<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col md="4">
              <FSearchInput
                urlName="services"
                :fetchDatas="fetchServiceDatas"
              />
            </b-col>
            <b-col md="4">
              <FCategoriesCom
                urlName="services"
                @getServiceCategories="getCategories"
                :isService="true"
              />
            </b-col>
            <b-col md="4">
              <FStatesCom urlName="services" @getStates="getStates" />
            </b-col>
            <!-- <b-col md="4">
              <FUser urlName="services" @getUsers="getUsers" />
            </b-col> -->

            <b-col
              md="12"
              class="d-flex align-items-center justify-content-end"
            >
              <div>
                <router-link to="/my-pages/services/create" class="d-block">
                  <b-button variant="primary">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">Add New Service</span>
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>

        <vue-good-table
          :columns="fields"
          :rows="serviceDatas"
          :theme="skin == 'dark' ? 'nocturnal' : ''"
          :pagination-options="{enabled : true}"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'categories'">
              <span
                style="font-size: 0.9rem; margin-right: 5px"
                v-for="c in props.row.categories"
                :key="c.id"
              >
                {{ c.name }}
              </span>
            </span>

            <span v-else-if="props.column.field == 'actions'">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'service-details', query: { id: props.row.id }, }">
                    <router-link :to="{ name: 'service-details', query: { id: props.row.id }, }" class="dropdown-item-con">
                      <feather-icon icon="BookOpenIcon" />
                      <span class="align-middle ml-50">Details</span>
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name : 'EditService' , params : {id : props.row.id}}">
                    <feather-icon icon="EditIcon" />
                    <router-link :to="{name : 'EditService' , params : {id : props.row.id}}" class="align-middle ml-50">Edit</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div style="margin: 1rem 0rem;display: flex;justify-content: end;">
              <b-pagination
                v-if="meta.current_page"
                v-model="meta.current_page"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                @change="changePage"
              ></b-pagination>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import store from "@/store";
import Table from "../components/Table.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import FStatesCom from "../components/FStatesCom.vue";
import FSearchInput from "../components/FSearchInput.vue";
import FUser from "../components/FUser.vue";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
  BFormTimepicker,
  BPagination
} from "bootstrap-vue";

export default {
  components: {
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    Table,
    FCategoriesCom,
    FStatesCom,
    FSearchInput,
    FUser,
    VueGoodTable,
  },
  setup() {
    const skin = ref(localStorage.getItem('vuexy-skin'));
    onMounted(() => {
        window.addEventListener('vuexy-skin-localstorage-changed', (event) => {
          skin.value = event.detail.storage;
        });
    });
    const { route, router } = useRouter();
    const fields = ref([
      { sortable: false, field: "id", label: "#" },
      { sortable: false, field: "title", label: "title" },
      { sortable: false, field: "sub_title", label: "sub title" },
      { sortable: false, field: "state.name", label: "state" },
      { sortable: false, field: "status", label: "publish" },
      { sortable: false, field: "categories", label: "categories" },
      { sortable: false, field: "actions", label: "actions" },
    ]);
    const spinner = ref(false);
    const serviceDatas = ref([]);
    const categories = ref([]);
    const users = ref([]);
    const states = ref([]);
    const pageNum = ref(1);
    const meta = ref({});

    //Datas Fetching
    const fetchServiceDatas = () => {
      spinner.value = true;
      if (router.app._route.query.page) {
        pageNum.value = router.app._route.query.page;
      }

      if (router.app._route.query.category_id) {
        pageNum.value = router.app._route.query.category_id;
      }

      // if (router.app._route.query.search) {
      //   pageNum.value = router.app._route.query.search;
      // }

      let queryKey = Object.keys(router.app._route.query)[0] || "page";
      if (router.app._route.query.search) {
        store
          .dispatch(
            "app-mypages/fetchDatas",
            `services?${queryKey}=${pageNum.value}&search=${router.app._route.query.search}`
          )
          .then((response) => {
            serviceDatas.value = response.data;
            meta.value = response.data.meta
            spinner.value = false;
          });
      } else {
        store
          .dispatch(
            "app-mypages/fetchDatas",
            `services?${queryKey}=${pageNum.value}`
          )
          .then((response) => {
            serviceDatas.value = response.data.data;
            meta.value = response.data.meta
            spinner.value = false;
          });
      }
    };
    fetchServiceDatas();

    const changePage = (e) => {
      router.push({name : 'apps-services' , query : { ...router.app._route.query , page : e}})
      pageNum.value = e;
      fetchServiceDatas();
    }
    // const fetchUsers = () => {
    //   spinner.value = true;
    //   store
    //     .dispatch("app-mypages/fetchDatas", "users")
    //     .then((response) => {
    //       users.value = response.data.data;
    //       spinner.value = false;
    //     });
    // };
    // fetchUsers();
    // Route Params
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      fetchServiceDatas();
    });
    const getCategories = (fetchedCategories) => {
      categories.value = fetchedCategories;
    };
    const getStates = (fetchedStates) => {
      states.value = fetchedStates;
    };

    // Data Deleting
    const delServiceData = (id) => {
      console.log(id);
      spinner.value = true;
      store
        .dispatch("app-mypages/delData", { path: "services", id })
        .then((response) => {
          fetchServiceDatas();
          spinner.value = false;
        });
    };
    // const getUsers = (fetchedUsers) => {
    //   users.value = fetchedUsers;
    // };

    return {
      skin,
      fields,
      spinner,
      pageNum,
      serviceDatas,
      getCategories,
      delServiceData,
      users,
      categories,
      states,
      getStates,
      fetchServiceDatas,
      meta,
      changePage
    };
  },
};
</script>
